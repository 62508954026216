import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';

import { actions } from 'features/users/redux';

import Input from 'components/Input';
import Paginator from 'components/Paginator';

import searchSvg from '../img/search.svg';
import arrowSvg from '../img/arrow.svg';

import './UsersList.scss';

const UsersList = () => {
  const b = block('users-list');
  const [values, setValues] = useState({
    username: '',
    page: 0,
    count: 30,
  });

  const dispatch = useDispatch();

  const debouncedSearch = useRef(debounce(newValues => {
    dispatch(actions.getUsersList(newValues));
  }, 700));

  useEffect(() => {
    dispatch(actions.getUsersList(values));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.page]);

  const changeFilterValue = e => {
    e.persist();
    setValues(ps => {
      const newValues = { ...ps, username: e.target.value };
      debouncedSearch.current(newValues);
      return (newValues);
    });
  };

  const handlePageChange = useCallback(page => {
    setValues(ps => ({ ...ps, page }));
  }, []);

  const locale = useSelector((state) => state.locale.locale, shallowEqual);
  const usersList = useSelector((state) => state.users.usersList, shallowEqual);

  const pageCount = useMemo(() => Math.ceil(usersList.totalCount / values.count)
  , [usersList.totalCount, values.count]);

  useEffect(() => {
    if (values.page > pageCount - 1 && pageCount - 1 >= 0)
      handlePageChange(pageCount - 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.page, pageCount]);

  const list = useMemo(
    () =>
      usersList.users
        ?.map((item) => (
          <Link
            key={item.id}
            className={b('item')}
            to={`/user-edit/${item.id}`}
          >
            <div
              className={b('item-nick')}
            >{`${item.nickname} (ID: ${item.id})`}</div>
            <div className={b('item-right')}>
              <div className={b('item-balance')}>{item.balance}</div>
              <div className={b('arrow-box')}>
                <SVGInline svg={arrowSvg} className={b('arrow').toString()} />
              </div>
            </div>
          </Link>
        )) || [],
    [b, usersList]
  );

  return (
    <div className={b()}>
      <div className={b('header')}>{locale.userCardBalances}</div>
      <div className={b('filter-block')}>
        <SVGInline svg={searchSvg} className={b('search-icon').toString()} />
        <Input
          placeholder={locale.search}
          value={values.username}
          callBack={changeFilterValue}
          style={{ paddingLeft: '2rem' }}
        />
      </div>
      <div className={b('subheader')}>
        <div className={b('user-card')}>
          <div className={b('item-nick')}>{locale.userCard}</div>
          <div className={b('item-right')}>
            <span className={b('bold-text')}>{usersList.quantity}</span>
          </div>
        </div>
        <div className={b('account-balance')}>
          <div className={b('item-nick')}>{locale.accountBalance}</div>
          <div className={b('item-right')}>
            <span className={b('bold-text')}>{usersList.totalBalance}</span>
          </div>
        </div>
      </div>
      <div className={b('list')}>{list}</div>
      <div className={b('paginator')}>
        <Paginator
          pageCount={pageCount}
          currentPage={values.page}
          onPageClick={handlePageChange}
        />
      </div>
    </div>
  );
};

export default UsersList;
