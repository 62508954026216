/* eslint-disable func-names */
import React, { useState, useMemo } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import arrowSVG from './img/arrow.svg';

import './Select.scss';

const b = block('select');

const Select = function ({ items, onChange, color = 'default' }) {
  const [isOpen, changeOpen] = useState(false);

  const itemsList = useMemo(
    () =>
      items.map(item => (
        <li
          key={item.key}
          className={b('item', { active: item.active })}
          onClick={() => {
            const changedList = items.map(item2 => ({
              ...item2,
              active: item2.key === item.key,
            }));
            const checkHasActive = changedList.filter(item2 => item2.active);
            if (checkHasActive.length === 0) changedList[0].active = true;
            onChange(changedList);
            changeOpen(false);
          }}
        >
          {item.renderElement}
        </li>
      )),
    [items, onChange, changeOpen]
  );

  const activeItem = useMemo(
    () => items.find(item => item.active) || items[0],
    [items]
  );

  return (
    <div
      className={b({ open: isOpen, color })}
      onClick={() => changeOpen(!isOpen)}
      onMouseLeave={() => changeOpen(false)}
    >
      <div className={b('item-selected')}>
        <div className={b('item-value-selected')}>
          {activeItem.selectedRenderElement}
        </div>
        <SVGInline
          className={b('item-arrow-selected').toString()}
          svg={arrowSVG}
        />
      </div>
      {isOpen && <ul className={b('items')}>{itemsList}</ul>}
    </div>
  );
};

export default Select;
