import React, { useMemo } from 'react';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import InputDate from 'components/InputDate/InputDate';
import Button from 'components/Button/Button';
import Select from 'components/Select/Select';
import InputNumber from 'components/InputNumber/InputNumber';

import './Filter.scss';

const b = block('withdrawal-requests-filter-mobile');

const Filter = ({ filterState }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const statuses = useMemo(() => {
    const items = filterState.values.statuses.map(p => ({
      ...p,
      renderElement: locale.withdrawalRequestHistoryStatuses[String(p.value)],
      selectedRenderElement:
        locale.withdrawalRequestHistoryStatuses[String(p.value)],
    }));
    return <Select items={items} onChange={filterState.onChangeStatus} />;
  }, [filterState, locale]);

  return (
    <form className={b()} onSubmit={filterState.onSubmit}>
      <div className={b('wrapper')}>
        <span className={b('item-label')}>ID</span>
        <span className={b('item-label')}>{locale.userId}</span>

        <div className={b('item-selector')}>
          <InputNumber
            value={filterState.values.id}
            onChangeNumber={v => filterState.onChangeId(v)}
            name='id'
            placeholder='Id'
          />
        </div>
        <div className={b('item-selector')}>
          <InputNumber
            value={filterState.values.playerId}
            onChangeNumber={v => filterState.onChangePlayerId(v)}
            name='playerId'
            placeholder={locale.userId}
          />
        </div>
      </div>
      <div className={b('wrapper')}>
        <span className={b('item-label')}>{locale.amount}</span>
        <span className={b('item-label')}>{locale.status}</span>
        <div className={b('item-selector')}>
          <InputNumber
            value={filterState.values.amount}
            onChangeNumber={v => filterState.onChangeAmount(v)}
            name='amount'
            placeholder={locale.amount}
            measure='TND'
          />
        </div>
        <div className={b('item-selector')}>{statuses}</div>
      </div>
      <span className={b('title')}>{locale.date}</span>
      <div className={b('wrapper', { isDateWrapper: true })}>
        <span className={b('item-label')}>{locale.from}</span>
        <span className={b('item-label')}>{locale.to}</span>

        <div className={b('item-date')}>
          <InputDate
            dateFormat='yyyy-MM-dd'
            selected={filterState.values.from}
            onChange={filterState.onChangeFrom}
          />
        </div>
        <div className={b('item-date')}>
          <InputDate
            dateFormat='yyyy-MM-dd'
            selected={filterState.values.to}
            onChange={filterState.onChangeTo}
          />
        </div>
      </div>

      <div className={b('button')}>
        <Button type='submit'>
          <span className={b('button-text')}>{locale.display}</span>
        </Button>
      </div>
    </form>
  );
};

export default Filter;
