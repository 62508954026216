/* eslint-disable no-continue */
import React, { useMemo, useState } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import ArrowSVG from './img/arrow.svg';

import './Paginator.scss';

const b = block('paginator');

const Paginator = ({ pageCount, currentPage, onPageClick }) => {
  const [isOpen, changeOpen] = useState(false);

  // массив страниц с пропущенной активной страницей
  const items = useMemo(() => {
    let result = [];
    for (let i = 0; i < pageCount; i += 1) {
      if (currentPage === i) continue;
      result.push(i);
    }
    return result;
  }, [pageCount, currentPage]);

  const itemsList = useMemo(
    () =>
      items.map(item => (
        <li key={item} className={b('item')} onClick={() => onPageClick(item)}>
          <span className={b('item-value')}>{item + 1}</span>
        </li>
      )),
    [items, onPageClick]
  );

  return (
    <div
      className={b({ open: isOpen })}
      onClick={() => changeOpen(!isOpen)}
      onMouseLeave={() => changeOpen(false)}
    >
      <div className={b('selected-item')}>
        <span className={b('selected-item-value')}>{currentPage + 1}</span>
        <div className={b('selected-item-arrow-wrapper')}>
          <SVGInline
            className={b('selected-item-arrow').toString()}
            svg={ArrowSVG}
          />
        </div>
      </div>
      {isOpen && <ul className={b('items')}>{itemsList}</ul>}
    </div>
  );
};

export default Paginator;
