import React from 'react';
import SVGInline from 'react-svg-inline';
import { useSelector } from "react-redux";

import Modal from "components/Modal";
import block from "bem-cn";

import crossSVG from '../img/cross.svg';
import './ImagePreview.scss';

const b = block('image-preview');


const ImagePreview = ({onClose, image}) => {
  const locale = useSelector(state => state.locale.locale);

  return (
    <Modal onClose={onClose}>
      <div className={b()}>
        <button className={b('close')} onClick={onClose}>
          <SVGInline className={b('cross').toString()} svg={crossSVG} />
        </button>
        <img className={b('image')} alt={locale.screenshot} src={image} />
      </div>
    </Modal>
  );
};

export default ImagePreview;