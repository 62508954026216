import React, { useEffect, useMemo } from "react";
import block from "bem-cn";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

import { actions } from "../redux";
// import Spinner from "components/Spinner";
import TreeRow from "./TreeRow/TreeRow";

import "./Tree.scss";

const b = block("tree");

const Tree = () => {
  const dispatch = useDispatch();

  const usersDataList = useSelector((state) => state.tree.users, shallowEqual);

  const rows = useMemo(
    () =>
      usersDataList.map((row) => (
        <TreeRow key={row.userId} nesting={[row.userId]} row={row} />
      )),
    [usersDataList]
  );

  useEffect(() => {
    dispatch(actions.getUsersByUserId());
    return () => dispatch(actions.getUsersByUserId());
  }, []);

  return (
    <div className={b()}>
      {rows}
    </div>
  );
};

export default Tree;
