/* eslint-disable max-len */
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { itemsOnPage } from './data';

dayjs.extend(customParseFormat);

export const convertData = ({
  from,
  to,
  statuses,
  amount,
  id,
  userId,
  playerId,
  page,
}) => {
  const params = new URLSearchParams();

  const resultStatuses = [
    ...statuses.filter(s => s.active && s.value !== -1).map(s => s.value),
  ].map(s => {
    if (String(s) !== 'all') params.append('status', String(s));
    return s;
  });

  params.append('amount', String(amount));
  params.append('withdrawal_id', String(id));
  params.append('target_user_id', String(playerId));
  params.append('from', dayjs(from).format('YYYY-MM-DD'));
  params.append('to', dayjs(to).add(1, 'day').format('YYYY-MM-DD'));
  params.append('page', String(++page));
  params.append('count', String(itemsOnPage));
  params.append('status', resultStatuses.join());

  return params;
  // return {
  //   date_from: dayjs(from).format('YYYY-MM-DD'),
  //   date_to: dayjs(to).add(1, 'day').format('YYYY-MM-DD'),
  //   status: resultStatuses.join(),
  //   page,
  //   count: itemsOnPage,
  // };
};
