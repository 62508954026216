import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import block from 'bem-cn';

import { actions } from 'features/users/redux';
import Input from 'components/Input';
import PopUp from 'features/users/view/UserEdit/PopUp';

import './ChangePas.scss';

const ChangePas = () => {
  const b = block('change-pas');
  const [pas, setPas] = useState('');
  const { id } = useSelector(state => state.auth);
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const [popUpIsOpen, setPopUpOpened] = useState(false);
  const dispatch = useDispatch();
  const [passwordDirty, setPasswordDirty] = useState(true)
  const [passwordError, setPasswordError] = useState(locale.errorPassword);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (passwordError) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [passwordError]);


  const blureHandler = (e) => {
    switch (e.target.name) {
      case 'password':
        setPasswordDirty(true)
        break
    }
  };

  const passwordHandler = (e) => {
    setPas(e.target.value)
    const re = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}/;
    const hasSixDigits = e.target.value?.split('')?.filter(value => /\d+/.test(value)).length > 5;
    if (!re.test(String(e.target.value)) || !hasSixDigits) {
      setPasswordError(locale.errorPassword)
      if(!e.target.value) {
        setPasswordError(locale.noPass)
      }
    } else {
      setPasswordError("")
    }
  };

  const onResetClick = useCallback(() => {
    dispatch(actions.resetPassword(id, setPopUpOpened, pas));
  }, [dispatch, id, pas]);

  return <div className={b()}>
    {popUpIsOpen && <PopUp password={pas} id={id} locale={locale} onClose={setPopUpOpened} />}
    {(passwordError && passwordDirty) && <div style={{color:'red'}}>{passwordError}</div>}
    <Input
      type="text"
      value={pas}
      callBack={e => setPas(e.currentTarget.value)}
      onChange={e => passwordHandler(e)}
      placeholder={locale.theNewPassword}
      name="password"
      onBlur={e => blureHandler(e)}
    />
    <button className={b('button')} disabled={!isValid} onClick={onResetClick}>
      {locale.changePas}
    </button>
  </div>
}

export default ChangePas;