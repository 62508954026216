import { initialState } from "./initial";
import { actionTypes } from "./actions";

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING:
      return { ...state, actionProcessing: action.payload };
    case actionTypes.GET_USERS_BY_USER_ID_SUCCESS:
      return { ...state, actionProcessing: false, users: action.payload };
    default:
      return { ...state };
  }
};
