import React, { useMemo } from 'react';
import block from 'bem-cn';

import './TopUpRequests.scss';

import Paginator from '../../../../components/Paginator';
import Filter from './Filter/Filter';
import useForm from '../../model/topUpRequests/useForm';
import Item from './Item/Item';

const b = block('top-up-requests');

const TopUpRequests = () => {
  const model = useForm();

  const itemsElements = useMemo(
    () =>
      model.outputState.items.map((t, i) => (
        <div key={`${i}_${t.id}`} className={b('item')}>
          <Item
            item={t}
            onResolveTopUpCard={model.inputState.onResolveTopUpCard}
          />
        </div>
      )),
    [model.outputState.items, model.inputState.onResolveTopUpCard]
  );

  return (
    <div className={b()}>
      <div className={b('filters')}>
        <Filter filterState={model.inputState} />
      </div>
      <div>
        {itemsElements}
        <div className={b('paginator')}>
          <Paginator
            pageCount={model.outputState.pageCount}
            currentPage={model.inputState.values.page}
            onPageClick={model.inputState.onPageChanged}
          />
        </div>
      </div>
    </div>
  );
};

export default TopUpRequests;
