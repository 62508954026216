import { addNotify } from 'features/notify';

const actionTypes = {
  ACTION_PROCESSING: 'auth/ACTION_PROCESSING',
  GET_WITHDRAWAL_REQUESTS: 'payment/GET_WITHDRAWAL_REQUESTS',
  GET_TOP_UP_REQUESTS: 'payment/GET_TOP_UP_REQUESTS',
};

const setActionProcessing = actionProcessing => {
  return async (dispatch, getState, extra) => {
    dispatch({
      type: actionTypes.ACTION_PROCESSING,
      payload: { actionProcessing },
    });
  };
};

const getWithdrawalRequests =
  filterData => async (dispatch, getState, extra) => {
    const { api } = extra;

    setActionProcessing(true);

    const response = await api.paymentRequests.getWithdrawalRequests(
      filterData
    );

    if (response.success) {
      dispatch({
        type: actionTypes.GET_WITHDRAWAL_REQUESTS,
        payload: {
          list: response.data.list,
          pageCount: response.data.pageCount,
        },
      });
    } else {
      addNotify({ text: 'Error', type: 'error' });
    }

    setActionProcessing(false);
  };

const getTopUpRequests = filterData => async (dispatch, getState, extra) => {
  const { api } = extra;

  setActionProcessing(true);

  const response = await api.paymentRequests.getTopUpRequests(filterData);

  if (response.success) {
    dispatch({
      type: actionTypes.GET_TOP_UP_REQUESTS,
      payload: {
        list: response.data.list,
        pageCount: response.data.pageCount,
      },
    });
  } else {
    addNotify({ text: 'Error', type: 'error' });
  }

  setActionProcessing(false);
};

const resolveWithdrawalCard =
  ({ data, callback }) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    setActionProcessing(true);

    const response = await api.paymentRequests.resolveWithdrawalCard(data);

    if (response.success) {
      dispatch(addNotify(locale.statusSuccess, 'success'));
      callback();
    } else {
      addNotify({ text: 'Error', type: 'error' });
    }

    setActionProcessing(false);
  };

const resolveTopUpCard =
  ({ data, callback }) =>
  async (dispatch, getState, extra) => {
    const { api } = extra;
    const { locale } = getState().locale;

    setActionProcessing(true);

    const response = await api.paymentRequests.resolveTopUpCard(data);

    if (response.success) {
      dispatch(addNotify(locale.statusSuccess, 'success'));

      callback();
    } else {
      addNotify({ text: 'Error', type: 'error' });
    }

    setActionProcessing(false);
  };

export {
  setActionProcessing,
  getWithdrawalRequests,
  getTopUpRequests,
  resolveWithdrawalCard,
  resolveTopUpCard,
  actionTypes,
};
