/* eslint-disable no-mixed-operators */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { actions as paymentRequestsActions } from 'features/paymentRequests';

import { convertData } from './convertData';
import useValidator from './useValidator';
import { itemsOnPage, statusItems } from './data';

dayjs.extend(customParseFormat);

const useForm = () => {
  const dispatch = useDispatch();

  const {
    actionProcessing,
    topUpRequests: { list, pageCount },
  } = useSelector(state => state.paymentRequests, shallowEqual);

  const [values, setValues] = useState({
    from: new Date(dayjs().add(-3, 'month').format('YYYY-MM-DDTHH:mm:ss')),
    to: new Date(dayjs().format('YYYY-MM-DDTHH:mm:ss')),
    statuses: statusItems,
    amount: '',
    id: '',
    userId: '',
    playerId: '',
    page: 0,
  });
  const validator = useValidator({ initFormValues: values });

  const disabled = useMemo(() => {
    return actionProcessing;
  }, [actionProcessing]);

  const onChangeFrom = useCallback(
    date => setValues(ps => ({ ...ps, from: date })),
    []
  );
  const onChangeTo = useCallback(
    date => setValues(ps => ({ ...ps, to: date })),
    []
  );
  const onChangeStatus = useCallback(
    value => setValues(ps => ({ ...ps, statuses: value })),
    []
  );
  const onChangeAmount = useCallback(
    value => setValues(ps => ({ ...ps, amount: value })),
    []
  );
  const onChangeId = useCallback(value => {
    setValues(ps => ({ ...ps, id: value }));
  }, []);
  const onChangeUserId = useCallback(
    value => setValues(ps => ({ ...ps, userId: value })),
    []
  );
  const onChangePlayerId = useCallback(
    value => setValues(ps => ({ ...ps, playerId: value })),
    []
  );
  const onPageChanged = useCallback(
    newPage =>
      setValues(ps => ({
        ...ps,
        page: newPage,
      })),
    []
  );

  // // если айтэмы приходят под конкретную страницу
  const items = useMemo(
    () =>
      list.map((t, index) => ({
        ...t,
        key: `${Object.values(t).join('-')}_${index}_${values.page}`,
      })),
    [list, values.page]
  );

  // если айтэмы приходят для всех страниц
  // const pageCount = useMemo(() => Math.ceil( / itemsOnPage), [list]);
  //const items = useMemo(
  //   () =>
  //    list
  //     .slice(
  //       values.page * itemsOnPage,
  //      values.page * itemsOnPage + itemsOnPage
  //    )
  //   .map((t, index) => ({
  //      ...t,
  //      key: `${Object.values(t).join('-')}_${index}_${values.page}`,
  //    })),
  // [list, values.page]
  //);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();

      const info = convertData({
        from: values.from,
        to: values.to,
        statuses: values.statuses,
        amount: values.amount,
        id: values.id,
        userId: values.userId,
        playerId: values.playerId,
        page: values.page,
      });
      dispatch(paymentRequestsActions.getTopUpRequests(info));
    },
    [
      values.statuses,
      values.amount,
      values.id,
      values.userId,
      values.playerId,
      values.from,
      values.to,
      values.page,
      dispatch,
    ]
  );

  useEffect(() => {
    dispatch(
      paymentRequestsActions.getTopUpRequests(
        convertData({
          from: values.from,
          to: values.to,
          statuses: values.statuses,
          amount: values.amount,
          id: values.id,
          userId: values.userId,
          playerId: values.playerId,
          page: values.page,
        })
      )
    );
  }, [values.page]);

  useEffect(() => {
    // защита от сильной связанности пагинатора с остальными фильтрами
    if (values.page > pageCount - 1 && pageCount - 1 >= 0)
      onPageChanged(pageCount - 1);
  }, [values.page, pageCount]);

  const onResolveTopUpCard = useCallback(
    ({ status, item, onClose }) => {
      dispatch(
        paymentRequestsActions.resolveTopUpCard({
          data: { payment_id: item.id, status, comment: '' },
          callback: () =>
            dispatch(
              paymentRequestsActions.getTopUpRequests(
                convertData({
                  from: values.from,
                  to: values.to,
                  statuses: values.statuses,
                  amount: values.amount,
                  id: values.id,
                  userId: values.userId,
                  playerId: values.playerId,
                  page: values.page,
                })
              )
            ),
        })
      );
      onClose();
    },
    [
      values.statuses,
      values.amount,
      values.id,
      values.userId,
      values.playerId,
      values.from,
      values.to,
      values.page,
      dispatch,
    ]
  );

  return useMemo(
    () => ({
      inputState: {
        values,
        validator,
        disabled,
        onChangeStatus,
        onChangeAmount,
        onChangeId,
        onChangeUserId,
        onChangePlayerId,
        onChangeFrom,
        onChangeTo,
        onPageChanged,
        onSubmit,
        onResolveTopUpCard,
      },
      outputState: { items, pageCount: Math.ceil(pageCount / itemsOnPage) },
    }),
    [
      values,
      validator,
      disabled,
      onChangeStatus,
      onChangeAmount,
      onChangeId,
      onChangeUserId,
      onChangePlayerId,
      onChangeFrom,
      onChangeTo,
      onPageChanged,
      onSubmit,
      items,
      pageCount,
      onResolveTopUpCard,
    ]
  );
};

export default useForm;
