export const locale = {
  changeLang: 'Changer langue',
  changePas: 'Changer pasword',
  logout: 'Deconnecter',
  other: 'Other',

  newPlayer: 'Nouveau client',
  userName: 'Nom de utilisateur',
  password: 'Mot de passe',
  createAccount: 'Creer un compte',
  '/user-create': 'Cash register - New player',
  '/total': 'Cash register - comptabilitee',
  '/': 'Menu',
  '/locale': 'Languagies',
  '/change-pas': 'Changer pasword',
  '/user-edit': 'Cash register - User edit',

  '/users-list': 'Cash register - balance des clients',
  '/tree': 'Tree',
  '/withdrawalRequests': 'Demandes de retrait',
  '/topUpRequests': 'Demandes de dépôt',

  userCardBalances: 'Balances des clients',
  userCard: 'Clients',
  accountBalance: 'Balance de compte',
  nickname: 'Nom de utilisateur',
  id: 'Identifiant',
  playerId: 'Identifiant du joueur',
  balance: 'Balance',
  ban: 'Bloquer',
  banSuccess: 'Bloquer success',
  unbanSuccess: 'Debloquer success',
  unban: 'Debloquer',
  resetPassword: 'Réinitialiser',
  resetSuccess: 'Mot de passe réinitialisee',
  networkError: 'Erreur de reseau',
  passwordError: 'Erreur',
  createSuccess: 'Succès de creation',
  topUp: 'Depot',
  withdrawal: 'Retrait',
  topUpSuccess: 'Succès  de depot',
  withdrawalSuccess: 'Succès  de retrait',
  topUpWithdrawal: 'Depot/Retrait',
  ok: 'Ok',
  theNewPassword: 'Nouveau mot de passe ',
  for: 'pour',

  '/transaction-history': 'clients - Historique de transactions',
  startDate: 'Date de debut',
  endingDate: 'Fin',
  display: 'Representer',
  email: 'E-mail',
  date: 'Date',
  amount: 'Montant',

  languageSelection: 'Selectionner langues',
  login: 'Me Connecter',

  cashRegister: 'Cash register',
  partners: 'Clients',
  createUser: {
    1: 'New user',
    2: 'New cashier',
    3: 'New admin',
  },
  balances: {
    1: 'User cards balances',
    2: 'Shop cards balances',
    3: 'Admins cards balances',
  },
  cashiersTransactions: {
    1: 'Cashier transactions',
    2: 'Shop transactions',
    3: 'Admins transactions',
  },
  transactionHistory: {
    1: 'Historique des transactions',
    2: 'Historique des transactions',
    3: 'Historique des transactions',
  },
  revenuBalances: {
    1: 'Revenu balances',
    2: 'Revenu balances',
    3: 'Revenu balances',
  },

  total: 'Totale',
  term: 'Terme',
  cashPayment: 'Payement en espece',
  cashPayout: 'Deboursement en espece',
  withdraw: 'Retrait',
  deposit: 'Depot',
  search: 'Search',
  errorPassword:
    'Le mot de passe doit comprendre: au moins 8 caractères, au moins 6 chiffres, une majuscule et une minuscule!',
  noPass: 'Le mot de passe ne doit pas être vide!',
  dataUpdate:
    'Les données sont mises à jour toutes les 12 heures. Par conséquent, les données du jour en cours peuvent être incorrectes',
  treeTitle: 'Tree',
  tree: {
    1: 'Tree',
    2: 'Tree',
    3: 'Tree',
  },
  withdrawalRequests: {
    1: 'Demandes de retrait',
    2: 'Demandes de retrait',
    3: 'Demandes de retrait',
  },
  topUpRequests: {
    1: 'Demandes de dépôt',
    2: 'Demandes de dépôt',
    3: 'Demandes de dépôt',
  },
  players: 'Joueurs',
  subusers: 'Sous-utilisateurs',
  dateCreation: 'Création de dates',
  status: 'Statut',
  redact: 'Rédiger',
  userId: "ID de l'utilisateur",
  from: 'Depuis',
  to: 'À',
  withdrawalRequestHistoryStatuses: {
    '-1': 'Tous les statuts',
    0: 'Créé',
    1: 'En cours',
    2: 'Approuvé',
    3: 'Annulé',
  },
  request: 'Demande',
  approve: 'Approuver',
  decline: 'Déclin',
  statusSuccess: 'Statut mis à jour avec succès',
  playerBalance: 'Solde du joueur',
  screenshot: 'Screenshot',
  image: 'Image',
};
