import React, { forwardRef } from 'react';
import block from 'bem-cn';
import DatePicker from 'react-datepicker';
import SVGInline from 'react-svg-inline';

import { formateDate } from 'shared/helpers';

import calendarSVG from './img/calendar.svg';
import arrowSVG from './img/arrow.svg';

import 'react-datepicker/dist/react-datepicker.css';

import './InputDate.scss';

const b = block('input-date-mobile');

const InputDate = ({ dateFormat, selected, isValid, onChange, color }) => {
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className={b('custom-date-input')} onClick={onClick} ref={ref}>
      <SVGInline
        className={b('custom-date-input-calendar').toString()}
        svg={calendarSVG}
      />
      <span className={b('custom-date-input-value')}>{formateDate(value)}</span>
      <SVGInline className={b('custom-date-input-arrow').toString()} svg={arrowSVG} />
    </div>
  ));

  return (
    <div className={b({ valid: isValid, color })}>
      <DatePicker
        dateFormat={dateFormat}
        selected={selected}
        onChange={onChange}
        customInput={<ExampleCustomInput />}
      />
    </div>
  );
};

export default InputDate;
