/* eslint-disable no-mixed-operators */
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const useValidator = ({ initFormValues }) => {
  const [values, setValues] = useState({});
  useEffect(() => {}, []);

  return useMemo(
    () => ({
      values,
    }),
    [values]
  );
};

export default useValidator;
