// сафари не переваривает специфические форматы

// превращает 2022-01-01 в 01.01.2022
const formateDate = date => {
  if (!date) return '__.__.____';
  const parts = date.split('-');
  return `${parts[2]}.${parts[1]}.${parts[0]}`;
};

export default formateDate;
