export default [
  {
    textId: 'cashRegister',
    items: [
      {
        textId: 'createUser',
        route: '/user-create',
      },
      {
        textId: 'balances',
        route: '/users-list',
      },
      {
        textId: 'cashiersTransactions',
        route: '/total',
      },
      {
        textId: 'withdrawalRequests',
        route: '/withdrawalRequests',
        onlyCashier: true,
      },
      {
        textId: 'topUpRequests',
        route: '/topUpRequests',
        onlyCashier: true,
      },
    ],
  },
  {
    textId: 'partners',
    items: [
      {
        textId: 'transactionHistory',
        route: '/transaction-history',
      },
    ],
  },
  {
    textId: 'treeTitle',
    items: [
      {
        textId: 'tree',
        route: '/tree',
      },
    ],
  },
];
