import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';

import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

import { formatNumberWithLimit } from './formatNumber';

import crossSVG from './img/cross.svg';
import downloadSVG from './img/download.svg';
import showSVG from './img/show.svg';

import ImagePreview from "./ImagePreview/ImagePreview";
import {downloadFile, getFileName} from "../../../model/topUpRequests/downloadFile";

import './Card.scss';

const b = block('withdrawal-requests-card');

const Card = ({ item, onClose, onResolveTopUpCard }) => {
  const locale = useSelector(state => state.locale.locale);

  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

  const imageName = useMemo(() => getFileName(item.image), [item.image])

  const closeImagePreview = () => {
    setIsImagePreviewOpen(false)
  }

  const openImagePreview = () => {
    setIsImagePreviewOpen(true)
  }

  const downloadImage = () => {
    downloadFile(item.image)
  }

  const amountFormatted = useMemo(
    () => formatNumberWithLimit(item.amount),
    [item.amount]
  );

  return (
    <Modal onClose={onClose}>
      <div className={b()}>
        <div className={b('header')}>
          <p className={b('header-title')}>{locale.request}</p>
          <SVGInline
            className={b('header-cross').toString()}
            svg={crossSVG}
            onClick={onClose}
          />
        </div>
        <div className={b('body')}>
          <div className={b('item')}>
            <span className={b('item-title')}>ID</span>
            <span className={b('item-value')}>{item.id}</span>
          </div>
          <div className={b('item')}>
            <span className={b('item-title')}>{locale.dateCreation}</span>
            <span className={b('item-value')}>{item.date}</span>
          </div>
          <div className={b('item')}>
            <span className={b('item-title')}>{locale.playerId}</span>
            <span className={b('item-value')}>{item.playerId}</span>
          </div>
          <div className={b('item')}>
            <span className={b('item-title')}>{locale.status}</span>
            <span className={b('item-value')}>
              {locale.withdrawalRequestHistoryStatuses[String(item.status)]}
            </span>
          </div>
          <div className={b('item')}>
            <span className={b('item-title')}>{locale.playerBalance}</span>
            <span className={b('item-value')}>{item.balance}</span>
          </div>
          <div className={b('item')}>
            <span className={b('item-title')}>{locale.amount}</span>
            <span className={b('item-value', { color: 'yellow' })}>
              {amountFormatted} {item.currency}
            </span>
          </div>
          {Boolean(item.image) && (
            <div className={b('item-image')}>
              <div className={b('item')}>
                <span className={b('item-title')}>{locale.screenshot}</span>
                <span className={b('item-value')}>{imageName}</span>
              </div>
              <div>
                <button className={b('item-button')} onClick={downloadImage}>
                  <SVGInline className={b('icon').toString()} svg={downloadSVG} />
                </button>
                <button className={b('item-button')} onClick={openImagePreview}>
                  <SVGInline className={b('icon').toString()} svg={showSVG} />
                </button>
              </div>
            </div>
          )}
        </div>
        <section className={b('data')}>
          <div className={b('button')}>
            <Button
              onClick={() => onResolveTopUpCard({ status: 2, item, onClose })}
              borderRadius={4}
              color='hollow'
            >
              <div className={b('button-text')}>{locale.approve}</div>
            </Button>
          </div>
          <div className={b('button')}>
            <Button
              onClick={() => onResolveTopUpCard({ status: 3, item, onClose })}
              borderRadius={4}
            >
              <div className={b('button-text')}>{locale.decline}</div>
            </Button>
          </div>
        </section>
      </div>
      {Boolean(item.image) && isImagePreviewOpen && (
        <ImagePreview image={item.image} onClose={closeImagePreview} />
      )}
    </Modal>
  );
};

export default Card;
