import React from 'react';
import block from 'bem-cn';

import './Input.scss';

const Input = ({
  value,
  name,
  callBack,
  color,
  placeholder = '',
  size = 'default',
  type = 'text',
  isRequired,
  measure = '',
  ...props
}) => {
  const b = block('input');
  return (
    <div className={b({ color })}>
      <input
        type={type}
        name={name}
        value={value}
        placeholder={placeholder || ''}
        onChange={callBack}
        className={b('native', { sizable: size, type, color })}
        required={isRequired}
        {...props}
      />
      {measure && <span className={b('measure')}>{measure}</span>}
    </div>
  );
};

export default Input;
