export const itemsOnPage = 4;

export const statusItems = [
  {
    value: -1,
    key: 'all',
    renderElement: '-1',
    selectedRenderElement: '-1',
    active: true,
  },
  {
    value: 0,
    key: 'Raw payment',
    renderElement: '0',
    selectedRenderElement: '0',
    active: false,
  },
  {
    value: 1,
    key: 'Accepted payment',
    renderElement: '1',
    selectedRenderElement: '1',
    active: false,
  },
  {
    value: 2,
    key: 'Rejected payment',
    renderElement: '2',
    selectedRenderElement: '2',
    active: false,
  },
  {
    value: 3,
    key: 'Refunded payment',
    renderElement: '3',
    selectedRenderElement: '3',
    active: false,
  },
];
