/* eslint-disable no-unused-expressions */
import React from 'react';

import Input from '../Input/Input';

const InputNumber = ({ onChangeNumber, needFilled, ...restProps }) => {
  return (
    <Input
      {...restProps}
      value={restProps.value || (needFilled ? 0 : '')}
      onChange={e => {
        let value = Number(e.target.value);
        if (e.target.value.length === 0) value = 0;
        if (e.target.value.length !== 0 && !Number(e.target.value))
          value = Number(restProps.value);
        value = Math.abs(value);
        if (!needFilled && e.target.value === '') value = '';
        onChangeNumber(value);
      }}
      type='text'
      color='white'
    />
  );
};

export default InputNumber;
