import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACTION_PROCESSING: {
      const actionProcessing = action.payload?.actionProcessing;

      if (actionProcessing !== undefined) {
        return { ...state, actionProcessing };
      }
      return state;
    }

    case actionTypes.GET_WITHDRAWAL_REQUESTS: {
      const list = action.payload?.list;
      const pageCount = action.payload?.pageCount;

      if (list !== undefined && pageCount !== undefined)
        return { ...state, withdrawalRequests: { list, pageCount } };
      return state;
    }

    case actionTypes.GET_TOP_UP_REQUESTS: {
      const list = action.payload?.list;
      const pageCount = action.payload?.pageCount;

      if (list !== undefined && pageCount !== undefined)
        return { ...state, topUpRequests: { list, pageCount } };
      return state;
    }

    default:
      return state;
  }
};
