import dayjs from 'dayjs';

export class PaymentAdminConverter {
  getImageUrl = url => `https://mycard.gospin365.com${url}`;
  convertRequests = data => {
    const list = data.requests
      .map(t => ({
        id: t.id,
        date: dayjs(t.create_date)
          .add(-4, 'hour')
          .format('YYYY-MM-DD HH:mm:ss'),
        amount: t.amount,
        status: t.status,
        currency: t.currency,
        playerId: t.user_id,
        balance: t.balance,
        image: t.img_url ? this.getImageUrl(t.img_url) : null,
      }))
      .sort(
        (a, b) =>
          Date.parse(dayjs(b.date).format('YYYY-MM-DD HH:mm:ss')) -
          Date.parse(dayjs(a.date).format('YYYY-MM-DD HH:mm:ss'))
      );

    return {
      list,
      pageCount: data.quantity,
    };
  };
}
