import BaseApi from '../BaseApi';
import { PaymentAdminConverter } from './PaymentRequestsConverter';

class PaymentAdminApi extends BaseApi {
  converter;

  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new PaymentAdminConverter();
  }

  getWithdrawalRequests = filters =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/admin_withdrawal`,
      filters,
      undefined,
      data => this.converter.convertRequests(data)
    );

  getTopUpRequests = filters =>
    this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/admin_deposit`,
      filters,
      undefined,
      data => this.converter.convertRequests(data)
    );

  resolveWithdrawalCard = data =>
    this.sendQuery(
      this.queryTypes.PATCH,
      `${this.baseUrl}/admin_withdrawal`,
      data,
      undefined,
      d => d
    );

  resolveTopUpCard = data =>
    this.sendQuery(
      this.queryTypes.PATCH,
      `${this.baseUrl}/admin_deposit`,
      data,
      undefined,
      d => d
    );
}

export default PaymentAdminApi;
