export function getFileName(url) {
  if (typeof url === "string" && !!url.length) {
    const arr = url.split("/");
    const extension = arr.at(-1).match(/(jpeg|png|jpg|gif)/i);

    return extension[0];
  }
}

export function downloadFile(url) {
  if (typeof url === "string" && !!url.length) {
    const arr = url.split("/");
    const imageName = arr.at(-1);
    const a = document.createElement("a");
    a.href = url;
    a.download = imageName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
